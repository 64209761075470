.form-input {
    @apply rounded w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm;
}

.action-button-blue {
    @apply inline-flex items-center w-full md:w-40 px-4 py-2 bg-blue-600 text-white font-bold rounded-md hover:bg-blue-700 transition-colors mb-2;
}

.action-button-green {
    @apply inline-flex items-center w-full md:w-40 px-4 py-2 bg-green-600 text-white font-bold rounded-md hover:bg-green-700 transition-colors mb-2;
}

.action-button-teal {
    @apply inline-flex items-center w-full md:w-40 px-4 py-2 bg-teal-600 text-white font-bold rounded-md hover:bg-teal-700 transition-colors mb-2;
}

.action-button-purple {
    @apply inline-flex items-center w-full md:w-40 px-4 py-2 bg-purple-600 text-white font-bold rounded-md hover:bg-purple-700 transition-colors mb-2;
}

.action-button-red {
    @apply inline-flex items-center w-full md:w-40 px-4 py-2 bg-red-600 text-white font-bold rounded-md hover:bg-red-700 transition-colors mb-2;
}

.cards-list-inline {
    @apply grid grid-cols-1 gap-6;
}

.card-flex {
    @apply bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow p-6 flex flex-wrap justify-between;
}

.card-flex-details-container {
    @apply flex items-center w-full md:w-auto;
}

.card-flex-actions-container {
    @apply flex flex-col items-start md:items-end w-full md:w-auto mt-4 md:mt-0;
}

.h-70vh {
    height: 70vh;
}